import '../../styles/blocks/_slider-large-cards.scss'
import Swiper, {EffectCreative, Navigation, Pagination} from 'swiper';
import 'swiper/scss'
import 'swiper/scss/effect-creative'
import 'swiper/scss/pagination';
import {breakpoints} from '../breakpoints'

Swiper.use([Navigation, Pagination, EffectCreative]);

const initSlider = () => {
    const slider = new Swiper('.js-slider-large-cards .swiper', {
        effect: "creative",
        loop: true,
        speed: 1000,
        slidesPerView: 'auto',
        centerMode: true,
        centeredSlides: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev', // triggered twice on both sliders
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        creativeEffect: {
            limitProgress: 3,
            prev: {
                translate: ["-120%", 0, -300],
            },
            next: {
                translate: ["120%", 0, -300],
            }
        },
        breakpoints: {
            0: {
                creativeEffect: {
                    limitProgress: 3,
                    prev: {
                        translate: ["-117%", 0, -300],
                    },
                    next: {
                        translate: ["117%", 0, -300],
                    }
                },
            },
            [breakpoints.lg]: {
                creativeEffect: {
                    limitProgress: 3,
                    prev: {
                        translate: ["-120%", 0, -300],
                    },
                    next: {
                        translate: ["120%", 0, -300],
                    }
                },
            }
        },
    });
}

const initCounters = () => {
    if ($('.js-slider-large-cards .js-counters').length > 0) {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                const intersecting = entry.isIntersecting
                if (intersecting) {
                    $('.js-slider-large-cards .js-counter').each(function () {
                        let $this = $(this)
                        let endValue = $this.data('end')
                        if (endValue) {
                            $({start: 0}).animate({end: $this.data('end')}, {
                                duration: 3700,
                                easing: 'swing', // can be anything
                                step: function () {
                                    // Update the element's text with rounded-up value:
                                    if (!isNaN(Math.round(this.end))) {
                                        $this.text(Math.round(this.end))
                                    }
                                }
                            });
                        }
                    });

                    observer.unobserve(entry.target)
                }
            })
        })

        observer.observe($('.js-slider-large-cards .js-counters')[0])
    }
}

$(window).on('load', function () {
    initSlider()
    initCounters()
});
